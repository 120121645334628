import React, { Fragment, PureComponent } from "react"
import classnames from "classnames"

import Button from "../../Button"
import Grid from "../../Grid"
import FeatureDetail from "../../FeatureDetail"
import { SUPPORT } from "../FeaturesNavigation"
import css from "./index.module.scss"

import MedalIcon from "./icn-TrackRecord.svg"
import BulbIcon from "./icn-Tips.svg"
import ThumbIcon from "./icn-ChatSupport.svg"

class Support extends PureComponent {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  componentDidMount() {
    const { updateHighlight } = this.props
    if (!this.observer) {
      this.observer = new IntersectionObserver(
        (entries) => {
          if (updateHighlight) {
            updateHighlight(SUPPORT, entries[0].intersectionRatio)
          }
        },
        {
          threshold: [0.05, 0.25, 0.5, 0.75, 0.95],
        }
      )
    }
    this.observer.observe(this.container.current)
  }

  componentWillMount() {
    if (this.observer) {
      this.observer.unobserve(this.container.current)
    }
  }

  render() {
    const { className } = this.props
    return (
      <Grid
        id="support"
        ref={this.container}
        className={classnames(css.container, className)}
      >
        <h2>Access to best-in-class support</h2>
        <div className={css.list}>
          <FeatureDetail
            className={css.item}
            icon={MedalIcon}
            title="Proven track record"
            detail={
              <Fragment>
                <p>
                  Our customer success team is smart, friendly, and here to get
                  you results
                </p>
                <a
                  href="https://apps.shopify.com/referralcandy/reviews"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read review
                </a>
              </Fragment>
            }
          />
          <FeatureDetail
            className={css.item}
            icon={BulbIcon}
            title="Expert guides and tips"
            detail={
              <Fragment>
                <p>
                  Learn guides and best practices from experts in referral
                  marketing
                </p>
                <a
                  href="https://www.referralcandy.com/resources"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read best practices
                </a>
              </Fragment>
            }
          />
          <FeatureDetail
            className={css.item}
            icon={ThumbIcon}
            title="Get your answers instantly"
            detail={
              <Fragment>
                <p>
                  Get all the answers you need in our user-friendly support
                  center
                </p>
                <a
                  href="https://help.referralcandy.com/support/solutions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  See Support Center
                </a>
              </Fragment>
            }
          />
        </div>
        <Button purpose="primary" href="https://my.referralcandy.com/signup">
          Try It For Free
        </Button>
      </Grid>
    )
  }
}

export default Support
