import React, { PureComponent } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import Grid from "../../Grid"
import { CHANNELS } from "../FeaturesNavigation"
import FeatureDetail from "../../FeatureDetail"
import css from "./index.module.scss"

import EmailIcon from "./icn-Email.svg"
import InviteIcon from "./icn-Newsletter.svg"
import NewCustomerIcon from "./icn-NewCustomers.svg"
import LandingPageIcon from "./icn-LandingPage.svg"
import EmbeddedIcon from "./icn-SignupForm.svg"
import CheckoutIcon from "./icn-Checkout.svg"
import PromotionVideo from "./promotion.mp4"

class Promote extends PureComponent {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  componentDidMount() {
    const { updateHighlight } = this.props
    if (!this.observer) {
      this.observer = new IntersectionObserver(
        (entries) => {
          if (updateHighlight) {
            updateHighlight(CHANNELS, entries[0].intersectionRatio)
          }
        },
        {
          threshold: [0.05, 0.25, 0.5, 0.75, 0.95],
        }
      )
    }
    this.observer.observe(this.container.current)
  }

  componentWillMount() {
    if (this.observer) {
      this.observer.unobserve(this.container.current)
    }
  }

  render() {
    const { data, className } = this.props
    return (
      <Grid
        id="channels"
        ref={this.container}
        className={classnames(css.container, className)}
      >
        <h2>
          Promote your referral program
          <br />
          across marketing channels
        </h2>
        <video autoPlay muted loop>
          <source src={PromotionVideo} type="video/mp4" />
          <Img
            fluid={data["channels"].childImageSharp.fluid}
            alt="Channel screenshots"
          />
        </video>
        <div className={css.list}>
          <FeatureDetail
            className={css.item}
            icon={EmailIcon}
            title="Send email blasts"
            detail="Invite all customers on your mailing lists to join your referral
    program"
          />

          <FeatureDetail
            className={css.item}
            icon={CheckoutIcon}
            title="Invite customers at checkout"
            detail="Show customers a popup after checkout to invite them to join"
          />

          <FeatureDetail
            className={css.item}
            icon={NewCustomerIcon}
            title="Email new customers"
            detail="Email customers an invite email after they buy or receive their products"
          />

          <FeatureDetail
            className={css.item}
            icon={LandingPageIcon}
            title="Use a landing page"
            detail="Send customers to a landing page where they can get their referral link"
          />

          <FeatureDetail
            className={css.item}
            icon={EmbeddedIcon}
            title="Embed a signup form"
            detail="Allow customers to join as members as they browser your online store"
          />

          <FeatureDetail
            className={css.item}
            icon={InviteIcon}
            title="Invite in existing newsletters"
            detail="Add referral links to your existing newsletters to invite customers to refer"
          />
        </div>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    channels: file(
      relativePath: { eq: "images/features/channels-screenshots.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1940) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`
export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <Promote {...props} data={data} />}
  />
)
