import React, { PureComponent } from "react"
import classnames from "classnames"

import Grid from "../../Grid"
import Button from "../../Button"
import FeatureDetail from "../../FeatureDetail"
import { AUTOMATION } from "../FeaturesNavigation"
import css from "./index.module.scss"

import PromoteIcon from "./icn-Promotion.svg"
import TrackIcon from "./icn-Referral.svg"
import FraudIcon from "./icn-FraudPrevention.svg"

class Automation extends PureComponent {
  constructor(props) {
    super(props)
    this.container = React.createRef()
  }

  componentDidMount() {
    const { updateHighlight } = this.props
    if (!this.observer) {
      this.observer = new IntersectionObserver(
        (entries) => {
          if (updateHighlight) {
            updateHighlight(AUTOMATION, entries[0].intersectionRatio)
          }
        },
        {
          threshold: [0.05, 0.25, 0.5, 0.75, 0.95],
        }
      )
    }
    this.observer.observe(this.container.current)
  }

  componentWillMount() {
    if (this.observer) {
      this.observer.unobserve(this.container.current)
    }
  }

  render() {
    const { className } = this.props
    return (
      <Grid
        id="automation"
        ref={this.container}
        className={classnames(css.container, className)}
        centeredClassName={css.centered}
      >
        <h2>Automate your entire referral program</h2>
        <div className={css.cards}>
          <div className={classnames(css.box, css.promote)}>
            <div className={css.card}>
              <div className={css.content}>
                <section>
                  <h4>Invite customers</h4>
                  <div className={css.row}>
                    <input type="text" placeholder="Immediately" disabled />
                    <label>after checkout</label>
                  </div>
                  <div className={css.row}>
                    <input type="text" placeholder="14 days" disabled />
                    <label>after the last email</label>
                  </div>
                </section>

                <section>
                  <h4>Invite mailing list</h4>
                  <ul>
                    <li>From Mailchimp</li>
                    <li className={css.selected}>From Shopify</li>
                    <li>From CSV</li>
                  </ul>
                </section>
              </div>

              <svg width="354px" height="438px" viewBox="0 0 354 438">
                <defs>
                  <linearGradient
                    x1="17.3993743%"
                    y1="54.4332425%"
                    x2="56.579436%"
                    y2="46.8909935%"
                    id="gradient1"
                  >
                    <stop stopColor="#ffffff" offset="0%" />
                    <stop stopColor="#d9edf7" offset="100%" />
                  </linearGradient>
                  <linearGradient
                    x1="105.520371%"
                    y1="43.1714997%"
                    x2="57.7629534%"
                    y2="55.6975675%"
                    id="gradient2"
                  >
                    <stop stopColor="#d9edf7" offset="0%" />
                    <stop stopColor="#ffffff" offset="100%" />
                  </linearGradient>
                </defs>
                <polygon
                  fill="url(#gradient2)"
                  points="-635 -33 564 -33 -257 401"
                />
                <polygon
                  fill="url(#gradient1)"
                  points="-329 -101 609 -101 492 -69 -41.3466667 205 -329 205"
                />
              </svg>
            </div>

            <FeatureDetail
              icon={PromoteIcon}
              title="Promote on your behalf"
              detail="Automatically invite your customers at chosen times such as after checkout"
            />
          </div>

          <div className={classnames(css.box, css.track)}>
            <div className={css.card}>
              <div className={css.content}>
                <h4>Referred purchases</h4>

                <div className={css.row}>
                  <div>
                    Successful referral by <strong>Jenny</strong>
                  </div>
                  <div className={css.reward_delivery}>
                    Reward will be sent in 3 days
                  </div>
                </div>

                <div className={css.row}>
                  <div>
                    Successful referral by <strong>John</strong>
                  </div>
                  <div className={css.reward_delivery}>
                    Reward will be sent in 4 days
                  </div>
                </div>

                <div className={css.row}>
                  <div>
                    Successful referral by <strong>Heather</strong>
                  </div>
                  <div className={css.reward_delivery}>
                    Reward will be sent in 4 days
                  </div>
                </div>
              </div>
              <svg width="354px" height="438px" viewBox="320 0 354 438">
                <defs>
                  <linearGradient
                    x1="17.3993743%"
                    y1="54.4332425%"
                    x2="56.579436%"
                    y2="46.8909935%"
                    id="gradient1"
                  >
                    <stop stopColor="#ffffff" offset="0%" />
                    <stop stopColor="#d9edf7" offset="100%" />
                  </linearGradient>
                  <linearGradient
                    x1="105.520371%"
                    y1="43.1714997%"
                    x2="57.7629534%"
                    y2="55.6975675%"
                    id="gradient2"
                  >
                    <stop stopColor="#d9edf7" offset="0%" />
                    <stop stopColor="#ffffff" offset="100%" />
                  </linearGradient>
                </defs>
                <polygon
                  fill="url(#gradient2)"
                  points="-635 -33 564 -33 -257 401"
                />
                <polygon
                  fill="url(#gradient1)"
                  points="-329 -101 609 -101 492 -69 -41.3466667 205 -329 205"
                />
              </svg>
            </div>

            <FeatureDetail
              icon={TrackIcon}
              title="Track successful referrals"
              detail="Detect successful referrals and distribute rewards to your customers"
            />
          </div>

          <div className={classnames(css.box, css.fraud)}>
            <div className={css.card}>
              <div className={css.content}>
                <h4>Referred purchases</h4>

                <div className={css.row}>
                  <div>
                    Successful referral by <strong>Dave</strong>
                  </div>
                  <div className={css.reward_delivery}>
                    <span className={classnames(css.warning, css.badge)}>
                      suspicious
                    </span>
                  </div>
                </div>

                <div className={css.row}>
                  <div>
                    Successful referral by <strong>Jenny</strong>
                  </div>
                  <div className={css.reward_delivery}>
                    Reward will be sent in 4 days
                  </div>
                </div>

                <div className={css.row}>
                  <div>
                    Successful referral by <strong>Dave</strong>
                  </div>
                  <div className={css.reward_delivery}>
                    <span className={classnames(css.danger, css.badge)}>
                      banned
                    </span>
                  </div>
                </div>
              </div>

              <svg width="354px" height="438px" viewBox="-550 -375 354 438">
                <defs>
                  <linearGradient
                    x1="17.3993743%"
                    y1="54.4332425%"
                    x2="56.579436%"
                    y2="46.8909935%"
                    id="gradient1"
                  >
                    <stop stopColor="#ffffff" offset="0%" />
                    <stop stopColor="#d9edf7" offset="100%" />
                  </linearGradient>
                  <linearGradient
                    x1="105.520371%"
                    y1="43.1714997%"
                    x2="57.7629534%"
                    y2="55.6975675%"
                    id="gradient2"
                  >
                    <stop stopColor="#d9edf7" offset="0%" />
                    <stop stopColor="#ffffff" offset="100%" />
                  </linearGradient>
                </defs>
                <polygon
                  fill="url(#gradient2)"
                  transform="scale(-1,-1)"
                  points="-635 -33 564 -33 -257 401"
                />
                <polygon
                  fill="url(#gradient1)"
                  transform="scale(-1,-1)"
                  points="-329 -101 609 -101 492 -69 -41.3466667 205 -329 205"
                />
              </svg>
            </div>

            <FeatureDetail
              icon={FraudIcon}
              title="Manage referral fraud"
              detail="Easily understand and take action against suspicious referral activities"
            />
          </div>
        </div>
        <Button purpose="primary" href="https://my.referralcandy.com/signup">
          Get Started Now
        </Button>
      </Grid>
    )
  }
}

export default Automation
