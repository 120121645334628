import React, { PureComponent } from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import classnames from "classnames"

import Grid from "../../Grid"
import Button from "../../Button"
import FeatureDetail from "../../FeatureDetail"
import { TEMPLATES } from "../FeaturesNavigation"
import css from "./index.module.scss"

import OptimizationIcon from "./optimization-icon.svg"
import MobileFirstIcon from "./mobile-first-icon.svg"
import CustomizationIcon from "./customization-icon.svg"
import DevelopersIcon from "./developers-icon.svg"

class Template extends PureComponent {
  constructor(props) {
    super(props)

    this.container = React.createRef()
  }

  componentDidMount() {
    const { updateHighlight } = this.props
    if (!this.observer) {
      this.observer = new IntersectionObserver(
        (entries) => {
          if (updateHighlight) {
            updateHighlight(TEMPLATES, entries[0].intersectionRatio)
          }
        },
        {
          threshold: [0.05, 0.25, 0.5, 0.75, 0.95],
        }
      )
    }
    this.observer.observe(this.container.current)
  }

  componentWillMount() {
    if (this.observer) {
      this.observer.unobserve(this.container.current)
    }
  }

  render() {
    const { data, className } = this.props
    return (
      <Grid
        id="templates"
        ref={this.container}
        className={classnames(css.container, className)}
      >
        <h2>
          Give your customers
          <br />
          the best brand experience
        </h2>
        <div className={css.sections}>
          <section className={css.features}>
            <FeatureDetail
              className={css.item}
              icon={OptimizationIcon}
              title="Optimization that boosts sale"
              detail="We use best practices that are proven to boost referral revenue for
          our templates"
            />

            <FeatureDetail
              className={css.item}
              icon={MobileFirstIcon}
              title="Mobile-first experience"
              detail="Our emails and pages work beautifully across devices"
            />

            <FeatureDetail
              className={css.item}
              icon={CustomizationIcon}
              title="Easy-to-use customization"
              detail="Use the templates out-of the box or add your brand favour in a few
          simple steps"
            />

            <FeatureDetail
              className={css.item}
              icon={DevelopersIcon}
              title="Full flexibility for developers"
              detail="You have total control over your templates via HTML & CSS editing"
            />

            <Button
              purpose="primary"
              href="https://my.referralcandy.com/signup"
            >
              Build Your Brand
            </Button>
          </section>
          <section className={css.demo}>
            <div style={{ width: "100%" }}>
              <Img
                style={{
                  maxWidth:
                    data["template"].childImageSharp.fluid.presentationWidth,
                  margin: "0 auto",
                }}
                fluid={data["template"].childImageSharp.fluid}
                alt="Template UI"
              />
            </div>
          </section>
        </div>
      </Grid>
    )
  }
}

const query = graphql`
  query {
    template: file(relativePath: { eq: "images/features/templates-ui.png" }) {
      childImageSharp {
        fluid(maxWidth: 643) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          presentationWidth
        }
      }
    }
  }
`

export default (props) => (
  <StaticQuery
    query={query}
    render={(data) => <Template data={data} {...props} />}
  />
)
