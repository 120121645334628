import React, { PureComponent } from "react"
import classnames from "classnames"
import Layout from "../components/Layout"
import Grid from "../components/Grid"
import Button from "../components/Button"

import css from "./features.module.scss"

import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import Navigation from "../components/Sections/FeaturesNavigation"
import Integration from "../components/Sections/FeaturesIntegration"
import MarketingApp from "../components/Sections/FeaturesMarketingApp"
import Automation from "../components/Sections/FeaturesAutomation"
import DataDriven from "../components/Sections/FeaturesDataDriven"
import Template from "../components/Sections/FeaturesTemplate"
import Promote from "../components/Sections/FeaturesPromote"
import Support from "../components/Sections/FeaturesSupport"

const query = graphql`
  query {
    banner: file(relativePath: { eq: "images/features/banner-features.png" }) {
      childImageSharp {
        fixed(height: 300) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`

const FeatureHeader = ({ className }) => {
  const { banner } = useStaticQuery(query)
  return (
    <Grid
      className={classnames(css.container, className)}
      centeredClassName={css.centered}
    >
      <Img
        fixed={banner.childImageSharp.fixed}
        alt="Referralcandy Features banner"
      />
      <h1>
        One solution to cover your
        <br />
        referral marketing needs
      </h1>
      <h3>
        ReferralCandy provides a complete set of building blocks to
        <br />
        support all referral marketing models
      </h3>
      <Button purpose="primary" href="https://my.referralcandy.com/signup">
        Launch Now →
      </Button>
    </Grid>
  )
}

class Features extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      highlight: null,
    }
    this.sections = {}
  }

  updateHighlightHandler = (section, ratio) => {
    this.sections[section] = ratio
    const max = Object.keys(this.sections).reduce(
      (curr, key) => {
        if (this.sections[key] > curr.val)
          return { key, val: this.sections[key] }
        return curr
      },
      { val: -1 }
    )
    this.setState({
      highlight: max.val > 0.1 ? max.key : "",
    })
  }

  componentDidMount() {
    if (window.location.hash) {
      const element = document.getElementById(window.location.hash.substring(1))
      const navigation = document.getElementById("navigation")
      if (!element) return
      this.setState({ highlight: window.location.hash.substring(1) })
      window.scrollTo({
        left: 0,
        top: element.offsetTop - (navigation.clientHeight + 200),
        behavior: "smooth",
      })
    }
  }

  metadata() {
    return {
      title: "ReferralCandy Features",
    }
  }

  render() {
    const { highlight } = this.state
    return (
      <Layout
        metadata={this.metadata()}
        pathname={this.props.location.pathname}
      >
        <FeatureHeader className={css.header} />
        <Navigation highlight={highlight} className={css.navigation} />
        <Integration
          updateHighlight={(section, highlight) =>
            this.updateHighlightHandler(section, highlight)
          }
          className={css.integration}
        />
        <MarketingApp
          updateHighlight={(section, highlight) =>
            this.updateHighlightHandler(section, highlight)
          }
          className={css.marketing_app}
        />
        <Automation
          updateHighlight={(section, highlight) =>
            this.updateHighlightHandler(section, highlight)
          }
          className={css.automation}
        />
        <DataDriven
          updateHighlight={(section, highlight) =>
            this.updateHighlightHandler(section, highlight)
          }
          className={css.data}
        />
        <Template
          updateHighlight={(section, highlight) =>
            this.updateHighlightHandler(section, highlight)
          }
          className={css.template}
        />
        <Promote
          updateHighlight={(section, highlight) =>
            this.updateHighlightHandler(section, highlight)
          }
          className={css.promote}
        />
        <Support
          updateHighlight={(section, highlight) =>
            this.updateHighlightHandler(section, highlight)
          }
          className={css.support}
        />
      </Layout>
    )
  }
}

export default Features
